const axios = require('axios');

class API {

    _baseURL = 'http://127.0.0.1:3000/api';

    _headers = {
        'Content-Type': 'application/json',
    }

    async _get(path, options) {

        const defaultOptions = { validateStatus: () => { return true; } }
        let response;

        try {

            response = await axios.get(`${this._baseURL}/${path}`, { ...defaultOptions, ...options });

        }
        catch (error) {

            return { success: false, data: 'there has been a network problem' };

        }

        return response.data;

    }

    get bardogs() {

        return {

            get: async (id) => {

                const headers = { ...this._headers };
                const response = await this._get(`bardogs/populate/${id}`, { headers });
                return response;

            },

        };
    }
}

export default new API();

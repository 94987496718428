import './App.css';
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";
import API from './utilities/api';
import { useEffect, useState } from 'react';

const App = () => {

    return (

        <Router>
            <Switch>
                <Route path="/bardogs/:id">
                    <Bardog />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>

    );
}

const Home = () => {
    return <h2>Coming soon...</h2>;
}

const Bardog = () => {

    const [dog, setDog] = useState();
    const [user, setUser] = useState();

    const { id } = useParams();

    const init = async (id) => {

        const response = await API.bardogs.get(id);

        if (response.success === false) {

            return;

        }

        setDog(response.data.dog);
        setUser(response.data.user);

    }

    useEffect(() => { init(id); }, [id]);

    if (dog == null) {

        return (
            <h1>Loading Dog...</h1>
        );

    }

    return (
        <div>
            <h1>Dog</h1>
            <h2>Name: {dog.name}</h2>
            <h2>Breed: {dog.breed}</h2>
            <h2>Aggressive: {dog.aggressive.toString()}</h2>
            <h2>Vaccinated: {dog.vaccinated.toString()}</h2>
            <h2>Sex: {dog.sex}</h2>
            <h2>DOB: {dog.dob}</h2>

            <h1>User</h1>
            <h2>Name: {user.name}</h2>
            <h2>Address: {user.address}</h2>
            <h2>Phone: {user.phone}</h2>
        </div>
    );
}

export default App;
